<template>
    <v-container fluid>
        <PageHeaderSlot showBackButton> </PageHeaderSlot>

        <v-form ref="dataForm" @submit.prevent="save()">
            <v-card>
                <v-card-text class="px-2">
                    <v-row class="ma-0">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="導師名稱" 
                                placeholder="導師名稱"
                                :fieldValue.sync="formData.name" 
                                dense 
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="類別" 
                                placeholder="選擇類別"
                                :fieldValue.sync="formData.user_type" 
                                dense 
                                :options="tutorTypeOptions"
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="性別" 
                                placeholder="選擇性別"
                                :fieldValue.sync="formData.gender" 
                                dense 
                                :options="$genderOptions"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="電話" 
                                placeholder="電話"
                                type="tel"
                                :fieldValue.sync="formData.phone" 
                                dense
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="分校" 
                                placeholder="選擇分校"
                                :fieldValue.sync="formData.schools" 
                                dense 
                                :options="tutorSchoolOptions"
                                isMultiple
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="備註" 
                                placeholder="備註"
                                :fieldValue.sync="formData.remark" 
                                dense
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                    </v-row>

                    <v-row class="ma-0">
                        <v-col cols="12" sm="6" :md="$validate.DataValid(id) ? '5' : '4'" :lg="$validate.DataValid(id) ? '4' : '3'">
                            <FormInput 
                                label="帳戶" 
                                placeholder="帳戶"
                                :fieldValue.sync="formData.username" 
                                required
                                dense
                                :dispatchUpdateOnChange="dispatchUpdateOnChange" 
                            >
                                <template slot="append-outer" v-if="$validate.DataValid(id)">
                                    <v-btn type="button" class="primary" depressed text @click="handlePasswordDialogOpen()" :loading="$store.getters.isLoading">更改密碼</v-btn>
                                </template>
                            </FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                v-if="!$validate.DataValid(id)"
                                label="密碼"
                                type="password"
                                :fieldValue.sync="formData.password" 
                                dense
                                :required="!$validate.DataValid(id)"
                                :customRules="[
                                    v => {
                                        return this.$validate.isValidPassword(v, false)
                                    }
                                ]"
                                showPwView
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- <v-btn v-if="$validate.DataValid(id)" type="button" class="primary mt-4" text depressed :loading="$store.getters.isLoading" @click="handlePasswordDialogOpen()">更改密碼</v-btn> -->

            <div class="d-flex align-center">
                <v-btn type="submit" class="primary mt-4" text depressed :loading="$store.getters.isLoading">儲存</v-btn>
                <v-btn v-if="$validate.DataValid(id)" type="button" class="mt-4 ml-4" outlined color="error" text depressed :loading="$store.getters.isLoading" @click="handleDelete()">刪除</v-btn>
            </div>
        </v-form>

        <ConfirmDialog ref="confirmDeleteDialog" title="是否刪除此導師？" deleteDescription="是否確定要刪除此導師？此動作無法復原。"></ConfirmDialog>

        <!-- 更改密碼 Dialog -->
        <FormDialog
            v-if="$validate.DataValid(id)"
            title="更改密碼"
            btnAlign="center"
            ref="passwordFormDialog"
            formRef="passwordForm"
            @submit-clicked="handlePasswordSubmit()"
        >
            <v-row class="ma-0">
                <v-col cols="12">
                    <FormInput 
                        label="新密碼"
                        type="password"
                        :fieldValue.sync="newPassword" 
                        dense
                        required
                        :customRules="[
                            v => {
                                return this.$validate.isValidPassword(v, false)
                            }
                        ]"
                    ></FormInput>
                </v-col>
                <v-col cols="12">
                    <FormInput 
                        label="確認新密碼"
                        type="password"
                        :fieldValue.sync="confirmPassword" 
                        dense
                        required
                        :customRules="[
                            v => {
                                return  !$validate.DataValid(v) || ($validate.DataValid(v) && v === newPassword) || '密碼不符'
                            }
                        ]"
                    ></FormInput>
                </v-col>
            </v-row>
        </FormDialog>
    
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import Datatable from '@/components/Datatable.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import FormInput from '@/components/formField/FormInput.vue';
import FormSelect from '@/components/formField/FormSelect.vue';
import FormDialog from '@/components/FormDialog.vue';

export default {
    name: 'TutorDetails',
    components: {
        Datatable,
        ConfirmDialog,
        FormInput,
        FormSelect,
        FormDialog
    },
    computed: { 
        id() {
            const id = parseInt(this.$route.params.id);
            if (!isNaN(id)) {
                return id;
            }

            return null;
        },
        dispatchUpdateOnChange() {
            if (this.$validate.DataValid(this.id)) {
                return true;
            } else {
                return false;
            }
        }
    },
    data: () => ({
        tutorTypeOptions: [
            { value: 'admin', text: '行政' },
            { value: 'tutor', text: '導師' },
            { value: 'manager', text: '管理員' },
        ],
        formData: {
            name: '',
            user_type: '',
            username: '',
            gender: '',
            phone: '',
            schools: [],
            remark: '',
            password: '',
        },
        newPassword: '',
        confirmPassword: '',
      
        tutorSchoolOptions: [],
    }),
    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getTutorById() {
            try {
                const data = await this.$Fetcher.GetTutorById(this.id);
                for(const key in this.formData) {
                    if (this.$validate.DataValid(data[key])) {
                        this.formData[key] = data[key];
                    }
                }
            } catch {
                this.setDialogMessage({
                    message: 'noData',
                    returnLink: {name: 'TutorList'}
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);

            const valid = this.$refs['dataForm'].validate();

            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            let payload = {
                name: this.formData.name,
                user_type: this.formData.user_type,
                schools: this.formData.schools,
                gender: this.formData.gender,
                username: this.formData.username,
                phone: this.formData.phone,
                remark: this.formData.remark
            };

            if (this.$validate.DataValid(this.id)) { // edit
                try {
                    await this.$Fetcher.UpdateTutor(this.id, payload);
                    this.$store.dispatch('setDataIsUpdated', false);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '儲存成功',
                        type: 'success',
                        refresh: true,
                        redirect: ''
                    });
                   
                } catch (err) {
                    this.setDialogMessage({
                        title: "儲存失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }

            } else { // create
                payload.password = this.formData.password;

                try {
                    await this.$Fetcher.NewAccount(payload);
                    this.$store.dispatch('setDataIsUpdated', false);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '新增成功',
                        type: 'success',
                        refresh: false,
                        redirect: 'TutorList'
                    });
                   
                } catch (err) {
                    this.setDialogMessage({
                        title: "新增失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },
     

        async handleDelete() {
            const confirm = await this.$refs.confirmDeleteDialog.show();
            if (confirm) {
                this.$store.dispatch('setLoading', true);
              
                try {
                    await this.$Fetcher.DeleteTutor(this.id);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '刪除成功',
                        type: 'success',
                        refresh: false,
                        redirect: 'TutorList'
                    });
                } catch (err) {
                    this.setDialogMessage({
                        title: "刪除失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },

        // ======== 更改密碼 =======
        async handlePasswordDialogOpen() {
            this.newPassword = '';
            this.confirmPassword = '';
            await this.$refs['passwordFormDialog'].$refs['passwordForm'].reset();
            this.$refs['passwordFormDialog'].show();
        },

        async handlePasswordSubmit() {
            try {
                await this.$Fetcher.TutorChangePassword(this.id, this.newPassword);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '更改成功',
                    type: 'success',
                    refresh: false
                });
                this.$refs['passwordFormDialog'].hide();
            } catch(err) {
                this.setDialogMessage({
                    title: "更改失敗",
                    message: err,
                    isError: true,
                    returnLink: null,
                });
                this.setShowDialog(true);
                this.$refs['passwordFormDialog'].setLoading(false);
            }
        },

        // ======== 分校 Options =======
        async getAllSchoolOptions() {
            try {
                const { data } = await this.$Fetcher.GetSchools();
                const filtered = data.filter(el => el.active === true);
                    if (this.$validate.DataValid(filtered)) {
                        this.tutorSchoolOptions = filtered.map(el => {
                        return {
                            value: el.id,
                            text: el.name
                        }
                    })
                }
            } catch {}
        }
    },
    async mounted() {
        await this.getAllSchoolOptions();
    },
    async created() {
        this.$store.dispatch('toggleLoadingPage', true);
        if (this.$validate.DataValid(this.id)) { // edit
            this.getTutorById();
        } else { // new
            this.$store.dispatch('toggleLoadingPage', false);
        }
    },

    // // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>